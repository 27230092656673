import { writable } from "svelte/store";

type LangSubPath = string;
type LangCode = string;
type LangValue = undefined | "en" | "se";

export const lang = writable<LangValue>();
export const defaultLang = "nb";
export const additionalLangsRef: Record<LangSubPath, LangCode> = {
  en: "en",
  se: "se",
};
const allLangsRef: Record<string, string> = {
  ...additionalLangsRef,
  nb: "nb",
};
export const langsOut: Map<string, string> = new Map([
  ["nb", "Norsk bokmål"],
  ["en", "English"],
  ["se", "Davvisámegiella"],
]);
// Regex var to match lang codes (except "nb" since its default and sub `lang`s props should then be undefined)
const langRegex = new RegExp(`^(${Object.values(additionalLangsRef).join("|")})$`);

export const setLangFromPath = (path?: string) => {
  const langValue = getLangFromPath(path);
  lang.set((langValue?.match(langRegex) ? langValue : undefined) as LangValue);
};

// `opt.all` checks all lang codes including default lang code
// `opt.default` always returns a lang code value
export const getLangFromPath = (
  subPath: string | undefined,
  opt: { all?: boolean; default?: boolean; } = { all: false, default: true }
): string | undefined => {
  if (!subPath) {
    return opt?.default ? defaultLang : undefined;
  } else {
    const ref = opt?.all ? allLangsRef : additionalLangsRef;
    return ref[subPath] ? ref[subPath] : opt?.default ? defaultLang : undefined;
  }
};

/**
 * getServerLang
 * @param lang - (LangSubPath) lang value reference
 * @returns {string} - (LangValue) always returns lang code for from server file params */
export const getServerLang = ({ lang }: { lang?: LangSubPath; }): LangValue =>
  getLangFromPath(lang, { default: true }) as LangValue;

export const getI18nDocumentLangs = (
  documentList: {
    language?: string;
  }[],
) => {
  const languages: string[] = [];

  documentList.forEach((item) => {
    if (item.language) {
      languages.push(item.language);
    }
  });

  return languages;
};

export default lang;
